import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const Grid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-columnHeaderTitle': {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.subtitle3,
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus':
    {
      outline: 'none',
    },
  '& .MuiDataGrid-checkboxInput': {
    visibility: 'hidden',
  },
  '& .MuiTablePagination-root': {
    overflow: 'hidden',
  },
  '& .MuiTablePagination-toolbar': {
    overflow: 'hidden',
  },
}))

export default Grid
