import { FC } from 'react'
import CoreWorkflow from '../../Workflow/Core'
import { ReactFlowProvider, useEdgesState, useNodesState } from '@xyflow/react'
import Box from '@mui/material/Box'

const defaultNodes: any[] = []
const defaultEdges: any[] = []

const JourneyView: FC = () => {
  const [nodes, setNodes] = useNodesState(defaultNodes)
  const [edges, setEdges] = useEdgesState(defaultEdges)

  return (
    <Box
      sx={{
        height: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CoreWorkflow
        edges={edges}
        nodes={nodes}
        setEdges={setEdges}
        setNodes={setNodes}
      />
    </Box>
  )
}

const Journey: FC = () => {
  return (
    <ReactFlowProvider>
      <JourneyView />
    </ReactFlowProvider>
  )
}

export default Journey
