import dayjs from 'dayjs'
import {
  CustomColumnOptions,
  DataType,
  ExportOptions,
  TableColumn,
} from './db.typing'
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { useApi } from '../../providers/ApiProvider'
import {
  convertToCSV,
  downloadCSV,
  formatHeaderName,
  getColumnType,
  getDefaultWidth,
} from './util'

const API_BASE_URL = '/api/table/'

const useTablesApi = () => {
  const api = useApi()

  const fetchTableStructure = async (
    tableName: string,
    columnDefs: GridColDef[] = [],
    hideFields: string[]
  ): Promise<{ columns: GridColDef[]; error: string }> => {
    try {
      const response = await api.get(`${API_BASE_URL}${tableName}/structure`)
      const columnInfo: TableColumn[] = response.data.columns

      if (columnInfo) {
        const generatedColumns: GridColDef[] = columnInfo
          .filter((e) => !hideFields.includes(e.column_name))
          .map((column: TableColumn) => {
            const override = columnDefs.find(
              (e) => e.field === column.column_name
            )
            const columnDef: GridColDef = {
              field: column.column_name,
              headerName: formatHeaderName(column.column_name),
              width: getDefaultWidth(column.data_type as DataType),
              cellClassName: 'small-cell',
              editable:
                column.column_name !== 'id' &&
                column.column_name !== 'created_at',
              type: getColumnType(column.data_type as DataType) as any,
              valueGetter:
                getColumnType(column.data_type as DataType) === 'dateTime'
                  ? (e: any) => new Date(e.value)
                  : undefined,
              valueFormatter:
                getColumnType(column.data_type as DataType) === 'dateTime'
                  ? (e: any) => dayjs(e.value).format('ddd, MMM D (h:mm A)')
                  : undefined,
              ...override,
            }

            if (column.data_type === 'boolean') {
              columnDef.type = 'boolean'
            }

            return columnDef
          })

        return {
          columns: generatedColumns,
          error: '',
        }
      }
      throw new Error('No column information received')
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      console.error('Error fetching table structure:', error)
      return {
        columns: [],
        error: errorMessage,
      }
    }
  }

  const fetchData = async (
    tableName: string,
    filterModel: GridFilterModel,
    sortModel: GridSortModel,
    paginationModel: GridPaginationModel
  ): Promise<{ rows: any[] }> => {
    try {
      const response = await api.post(`${API_BASE_URL}${tableName}/data`, {
        filterModel,
        sortModel,
        paginationModel,
      })
      return {
        rows: response.data.rows,
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      console.error('Error fetching data:', error)
      throw new Error(errorMessage)
    }
  }

  const countRows = async (tableName: string): Promise<{ count: number }> => {
    try {
      const response = await api.get(`${API_BASE_URL}${tableName}/count`)
      return {
        count: response.data.count || 0,
      }
    } catch (error) {
      console.error('Error counting columns:', error)
      return {
        count: 0,
      }
    }
  }

  const updateCell = async (
    tableName: string,
    id: number,
    field: string,
    value: any
  ): Promise<void> => {
    try {
      await api.patch(`${API_BASE_URL}${tableName}/${id}`, {
        [field]: value,
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      console.error('Error updating cell:', error)
      throw new Error(errorMessage)
    }
  }

  const exportAllData = async (
    tableName: string,
    filterModel: GridFilterModel,
    sortModel: GridSortModel,
    paginationModel: GridPaginationModel,
    exportOptions: ExportOptions,
    customColumnOptions: Record<string, CustomColumnOptions>,
    columns: GridColDef[],
    count: number = 10000
  ): Promise<void> => {
    try {
      const response = await api.post(
        `${API_BASE_URL}${tableName}/export`,
        {
          filterModel,
          sortModel,
          paginationModel,
        },
        {
          params: { limit: count },
        }
      )

      if (response.data.rows) {
        const csv = convertToCSV(
          response.data.rows,
          exportOptions,
          columns,
          customColumnOptions
        )
        downloadCSV(csv, exportOptions)
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Export failed'
      console.error('Error exporting data:', error)
      throw new Error(errorMessage)
    }
  }

  return {
    exportAllData,
    updateCell,
    countRows,
    fetchData,
    fetchTableStructure,
  }
}

export default useTablesApi
