import { useCallback, FC, Dispatch, SetStateAction } from 'react'
import {
  Edge,
  ReactFlow,
  Controls,
  Background,
  addEdge,
  Connection,
  ConnectionMode,
  applyNodeChanges,
  applyEdgeChanges,
  MiniMap,
} from '@xyflow/react'
import Box from '@mui/material/Box'
import FileUploadNode from '../nodes/FileUpload'
import TextNodeComponent from '../nodes/TextNode'
import CustomNode from '../nodes/CustomNode'

interface CoreWorkflowProps {
  nodes: any[]
  setNodes: Dispatch<SetStateAction<any[]>>
  edges: any[]
  setEdges: Dispatch<SetStateAction<any[]>>
}

const nodeTypes = {
  custom: CustomNode,
  fileUpload: FileUploadNode,
  text: TextNodeComponent,
}

const CoreWorkflow: FC<CoreWorkflowProps> = ({
  edges,
  nodes,
  setNodes,
  setEdges,
}) => {
  const onNodesChange = useCallback(
    (changes: any) => {
      setNodes((nds) => applyNodeChanges(changes, nds))
    },
    [setNodes]
  )

  const onEdgesChange = useCallback(
    (changes: any) => {
      setEdges((eds) => applyEdgeChanges(changes, eds))
    },
    [setEdges]
  )

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  )
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        connectionMode={ConnectionMode.Loose}
        onEdgesDelete={(...e) => {
          console.log('Removing :: ', e)
        }}
        onEdgeClick={(e, edge) => {}}
        onEdgeContextMenu={(...e) => {
          console.log(e)
        }}
        onNodeDoubleClick={(_, e) => {
          setNodes((nds) =>
            nds.map((node) => {
              if (node.id === e.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isEditing: true,
                  },
                }
              }
              return node
            })
          )
          console.log('Doubled', e)
        }}
        fitView
        snapToGrid
        snapGrid={[20, 20]}
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </Box>
  )
}

export default CoreWorkflow
