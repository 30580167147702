import { FC, useEffect, useState } from 'react'
import { generateJwt } from './util'
import Loader from '../../Loader'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router'
import useReportsApi, { ReportDetail } from './api'
import { useQuery } from '@tanstack/react-query'
import { Protect } from '@clerk/clerk-react'
import TableEditor from '../../TableEditor'
import useTablesApi from '../../TableEditor/api'
import AnimatedProgress from '../../common/ReportsProgress'

interface ExploDashboard
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  updateUrlParams: boolean
  isProduction: boolean
  environment?: string
  variables: string
  'dash-jwt'?: string
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': ExploDashboard
    }
  }
}

const TableReport: FC<{ data: ReportDetail }> = ({ data }) => {
  const { fetchData } = useTablesApi()

  const tableData = useQuery({
    queryKey: ['table-data', data.score_view],
    queryFn: () =>
      fetchData(data.score_view || '', { items: [] }, [], {
        pageSize: 1,
        page: 0,
      }),
    enabled: Boolean(data.score_view),
  })

  return (
    <>
      <Box display={'flex'} alignItems={'center'} width={'100%'} gap={1}>
        <Typography variant="h6" sx={{ flex: '1 0 auto' }}>
          {data.title}
        </Typography>
        <AnimatedProgress
          value={(tableData.data?.rows[0]?.completion_score || 0) * 100}
        />
      </Box>
      <TableEditor
        hideHeader
        tableName={data.table_name}
        readonly={!data.editable}
        readonlyFields={data.readonlyFields || []}
        onEditComplete={() => {
          setTimeout(() => {
            tableData.refetch()
          }, 100)
        }}
      />
    </>
  )
}

const ExploReport: FC<{ data: ReportDetail }> = ({ data }) => {
  const [jwt, setJwt] = useState('')
  useEffect(() => {
    if (data) {
      generateJwt(data.explo_customer_id, data.explo_embed_id).then((e) => {
        setJwt(e)
      })
    }
  }, [data])

  useEffect(() => {
    const listener = async (e: any) => {
      // generate a new JWT here
      if (data) {
        const jwt = await generateJwt(
          data.explo_customer_id,
          data.explo_embed_id
        )
        setJwt(jwt)
        window.dispatchEvent(
          new CustomEvent('updateCustomerJwt', { detail: { jwt } })
        )
      }
    }

    window.addEventListener('customerJwtExpired', listener)
    return () => {
      window.removeEventListener('customerJwtExpired', listener)
    }
  }, [data])

  if (!jwt) {
    return <Loader />
  }

  return (
    <Protect
      permission="org:feature:read_data_view"
      fallback={
        <Typography variant="h2">
          You are not allowed to access this page
        </Typography>
      }
    >
      <Box
        sx={({ typography }) => ({
          '& *': {
            fontFamily: typography.fontFamily,
          },
        })}
      >
        <explo-dashboard
          dash-jwt={jwt}
          updateUrlParams={true}
          isProduction={true}
          variables={JSON.stringify({})}
        />
      </Box>
    </Protect>
  )
}

const Reports: FC = () => {
  const params = useParams()

  const { getReportById } = useReportsApi()

  const { data, isLoading } = useQuery({
    queryKey: ['report-data', params.id],
    queryFn: () => getReportById(params.id || ''),
  })

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return <></>
  }

  if (data.source === 'explo') {
    return <ExploReport data={data} />
  }

  return <TableReport data={data} />
}

export default Reports
