import { Handle, NodeProps, Position } from '@xyflow/react'
import { CustomNodeProps } from '../typing'
import { Box, Paper, Typography } from '@mui/material'

const handleStyle: React.CSSProperties = {
  width: '12px',
  height: '12px',
  background: '#555',
  border: '2px solid white',
}

// Custom Node Component
const CustomNode: React.FC<NodeProps<CustomNodeProps>> = ({ data }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        width: '240px',
        p: 2,
        position: 'relative',
        bgcolor: 'background.paper',
      }}
    >
      <Handle type="target" position={Position.Left} style={handleStyle} />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: data.color,
          }}
        >
          {data.icon}
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
            {data.label}
          </Typography>
          <Typography variant="subtitle3" sx={{ color: 'text.secondary' }}>
            {data.description}
          </Typography>
        </Box>
      </Box>
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </Paper>
  )
}

export default CustomNode
