import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import MeetingPage from '../MeetingPage'
import LandingLayout from '../LandingLayout'
import Home from '../Home'
import Meetings from '../Meetings'
import NotFoundPage from '../NotFound'
import Reports from '../MyData/Reports'
import Helpcenter from '../Helpcenter'
import MyData from '../MyData'
import Page from '../Page'
import ActionItems from '../ActionItems'
import PostHogPageviewTracker from '../PostHogPageviewTracker'
import { usePostHog } from 'posthog-js/react'
import { Protect, useOrganization, useUser } from '@clerk/clerk-react'
import TableEditor from '../TableEditor'
import CallLogs from '../CallLogs'
import WorkflowAutomation from '../Workflow'
import AnimatedFlow from '../Workflow/Animated'
import Outreach from '../Outreach'
import CallLog from '../Outreach/CallLog'
import CallDetail from '../Outreach/CallDetailFlow'
import Individual from '../Individual'
import Journey from '../Individual/Journey'

const queryClient = new QueryClient()

const App = () => {
  const posthog = usePostHog()
  const { user } = useUser()
  const { organization } = useOrganization()
  useEffect(() => {
    if (posthog && user && organization) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.id, {
        email: user?.primaryEmailAddress?.emailAddress,
        name: user?.fullName,
      })
      posthog?.group('company', organization?.id)
    }
  }, [posthog, user, organization])

  return (
    <>
      <PostHogPageviewTracker />
      <Routes>
        <Route
          path="/"
          element={
            <LandingLayout>
              <Outlet />
            </LandingLayout>
          }
        >
          <Route path={''} element={<Home />} />
          <Route path={'my-data'} element={<Outlet />}>
            <Route path={''} element={<MyData />} />
            <Route path={':id'} element={<Reports />} />
          </Route>
          <Route path={'my-meetings'} element={<Meetings my={true} />}>
            <Route path={':id'} element={<MeetingPage />} />
          </Route>
          <Route path={'pages'} element={<Outlet />}>
            <Route path="" element={<></>} />
            <Route path={':id'} element={<Page />} />
          </Route>
          <Route path={'meetings'} element={<Meetings my={false} />}>
            <Route path={':id'} element={<MeetingPage />} />
          </Route>
          <Route path={'help-center'} element={<Helpcenter />} />
          <Route path={'actions'} element={<ActionItems />} />
          <Route path={'call-logs'} element={<CallLogs />} />
          <Route
            path={'phone'}
            element={<TableEditor tableName="phone_manager" />}
          />
          <Route path={'workflow'} element={<AnimatedFlow />} />
          <Route path={'auto'} element={<Outlet />}>
            <Route path="" element={<></>} />
            <Route path={':id'} element={<WorkflowAutomation />} />
          </Route>
          <Route path={'individual'} element={<Outlet />}>
            <Route path="" element={<Individual />} />
            <Route path={':indvidual_id'} element={<Journey />} />
          </Route>
          <Route
            path={'begin-outreach'}
            element={
              <Protect role="org:global_admin">
                <Outlet />
              </Protect>
            }
          >
            <Route path={''} element={<Outreach />} />
            <Route path={':campaign-id'} element={<Outlet />}>
              <Route path={''} element={<CallLog />} />
              <Route path={'detail/:call-id'} element={<CallDetail />} />
            </Route>
          </Route>
          <Route path={'*'} element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

const CoreApp: FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  )
}
export default CoreApp
