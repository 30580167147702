import Navbar from '../Navbar'
import { FC, PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

const LandingLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ height: '100vh', display: 'flex' }}>
      <Navbar />
      <Box
        component={'main'}
        sx={{ height: 'calc(100vh - 64px)', mt: 8, width: '100%' }}
      >
        <Box sx={{ p: 2 }}>{children}</Box>
      </Box>
    </Box>
  )
}
export default LandingLayout
