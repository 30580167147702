// AxiosProvider.tsx

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  PropsWithChildren,
} from 'react'
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { useAuth } from '@clerk/clerk-react'

// Create a Context for the Axios instance
const AxiosContext = createContext<AxiosInstance>(axios)

// Create a Provider component
const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getToken } = useAuth()
  const axiosInstance = useMemo(() => axios.create(), [])

  useEffect(() => {
    //@ts-ignore
    window.getToken = getToken
    // Function to add the Bearer token to request headers
    const requestInterceptor = async (
      config: InternalAxiosRequestConfig
    ): Promise<InternalAxiosRequestConfig> => {
      const token = await getToken()
      if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    }

    // Add the interceptor to the Axios instance
    const interceptor = axiosInstance.interceptors.request.use(
      (config) => requestInterceptor(config),
      (error) => Promise.reject(error)
    )

    // Eject the interceptor when the component unmounts
    return () => {
      axiosInstance.interceptors.request.eject(interceptor)
    }
  }, [getToken, axiosInstance])

  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  )
}

// Custom hook to use the Axios instance
const useApi = (): AxiosInstance => {
  const context = useContext(AxiosContext)
  if (!context) {
    throw new Error('useAxios must be used within an AxiosProvider')
  }
  return context
}

export { ApiProvider, useApi }
