import { FC } from 'react'
import TableEditor from '../TableEditor'
import MuiLink from '@mui/material/Link'
import { Timeline } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const Individual: FC = () => {
  return (
    <TableEditor
      tableName="individual_info"
      readonly
      hideFields={['organization']}
      columOverrides={[
        {
          field: 'patient_id',
          headerName: 'Patient ID',
          renderCell: (params) => {
            return (
              <MuiLink
                component={Link}
                to={params.row.patient_id}
                display={'flex'}
                alignItems={'center'}
                gap={1}
                sx={{
                  color: 'text.secondary',
                  textDecorationColor: 'text.secondary',
                }}
                underline="hover"
              >
                {params.row.patient_id}{' '}
                <Timeline
                  fontSize="small"
                  sx={{ fontSize: '14px', height: '14px', width: '14px' }}
                />
              </MuiLink>
            )
          },
        },
      ]}
    />
  )
}

export default Individual
