import React, { useMemo } from 'react'
import { NodeProps, Position, Handle, useReactFlow, Node } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import Box from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { CustomNodeData, TextNode } from '../typing'
import IconRenderer from '../../common/Icon'
import IconAutocomplete from '../../common/IconAutocomplete'
import ContextMenu from '../ContextMenu'
import { Delete, Edit, LinkRounded } from '@mui/icons-material'
import { Link } from 'react-router-dom'

const handleStyle: React.CSSProperties = {
  width: '12px',
  height: '12px',
  background: '#555',
  border: '2px solid white',
}
// Custom Node Component
const TextNodeComponent: React.FC<NodeProps<TextNode>> = ({ data, id }) => {
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number
    mouseY: number
  } | null>(null)

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    )
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  const { updateNode, getNode, deleteElements, getEdges } = useReactFlow()
  // eslint-disable-next-line
  const nodeInfo = useMemo(() => getNode(id), [id])

  const handleChange = (e: string = '', field: keyof CustomNodeData): void => {
    const updatedData = {
      ...data,
      [field]: e,
    }
    const nodeUpdate: Partial<Node> = {
      ...nodeInfo,
      data: updatedData,
    }
    updateNode(id, nodeUpdate)
  }

  return (
    <>
      <Paper
        elevation={3}
        onContextMenu={handleContextMenu}
        sx={{
          p: 2,
          position: 'relative',
          bgcolor: 'background.paper',
        }}
      >
        <Handle type="target" position={Position.Left} style={handleStyle} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexDirection: 'column',
          }}
        >
          {data.isEditing ? (
            <>
              <IconAutocomplete
                selectedIcon={data.icon}
                onChange={(e) => handleChange(e?.name, 'icon')}
              />
              <TextField
                size="small"
                variant="standard"
                type="text"
                value={data.label}
                onChange={(e) => handleChange(e.target.value, 'label')}
                placeholder="Label"
              />
              <TextField
                size="small"
                variant="standard"
                type="text"
                value={data.description}
                onChange={(e) => handleChange(e.target.value, 'description')}
                placeholder="Description"
              />
              <TextField
                size="small"
                variant="standard"
                type="text"
                value={data.url || ''}
                onChange={(e) => handleChange(e.target.value, 'url')}
                placeholder="url"
              />
            </>
          ) : (
            <>
              <IconRenderer iconName={data.icon} />
              <Box display={'flex'} alignItems={'center'} gap={0.5}>
                <Typography variant="body1">{data.label}</Typography>
                {data.url && (
                  <MuiLink component={Link} to={data.url}>
                    {' '}
                    <LinkRounded />
                  </MuiLink>
                )}
              </Box>
              <Typography variant="body3">{data.description}</Typography>
            </>
          )}
        </Box>
        <Handle type="source" position={Position.Right} style={handleStyle} />
      </Paper>
      <ContextMenu contextMenuAnchor={contextMenu} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            updateNode(id, {
              ...nodeInfo,
              data: { isEditing: true, ...nodeInfo?.data },
            })
            handleClose()
          }}
        >
          <ListItemIcon>
            <Edit color="secondary" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            const edges = getEdges()
            const edgeToDelete = edges.filter(
              (e) => e.source === id || e.target === id
            )
            deleteElements({ nodes: [{ id }], edges: edgeToDelete })
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </ContextMenu>
    </>
  )
}

export default TextNodeComponent
